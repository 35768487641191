import {Component, DestroyRef, inject, OnDestroy, OnInit} from '@angular/core';
import {gql} from '../../../../gql';
import {Apollo} from 'apollo-angular';
import {QueryResult} from '../../../shared/utils';
import {ActivatedRoute} from '@angular/router';
import {catchError, exhaustMap, filter, Subject, Subscription, switchMap} from 'rxjs';
import {ErrorService} from '../../../shared/services/error.service';
import {NewcomerTimelineComponent_TimelineFragmentFragment, PlacementStatus} from '../../../../gql/graphql';
import {FormControl} from '@angular/forms';
import {MessageService} from 'primeng/api';
import {PLACEMENT_STATUS_OPTIONS} from '../../newcomer-overview/newcomer-overview.component';
import {models} from "../../../shared/models";
import {FormHelperService} from "../../../shared/services/form-helper.service";
import {FormSaveHelper} from '@vasio-nl/valow';
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

const TIMELINE_QUERY = gql(/* GraphQL */`
  query NewcomerTimelineComponent_timeline($id: ID!) {
    newcomer(id: $id) {
      id
      timeline {
        ...NewcomerTimelineComponent_timelineFragment
      }
    }
  }

  fragment NewcomerTimelineComponent_timelineFragment on timelineItem {
    ...on NewComerStatusHistory {
      id
      createdAt
      oldStatus
      status
    }
    ...on Note {
      id
      createdAt
      text
    }
    ...on PlacementOffer {
      id
      createdAt
      location {
        ...on WegwijzerLocationType {
          id
          name
        }
      }
    }
  }
`);

const RIGHTS_QUERY = gql(/* GraphQL */`
  query NewcomerTimelineComponent_rights {
    me {
      id
      managerHasAccessToSensitiveMessages
    }
  }
`);

type NewcomerType = NonNullable<QueryResult<typeof TIMELINE_QUERY>['newcomer']>['__typename'];
const NewcomerTypeName: NewcomerType = 'NewComer';

@Component({
  selector: 'app-newcomer-timeline',
  templateUrl: './newcomer-timeline.component.html',
  styleUrls: ['./newcomer-timeline.component.scss']
})
export class NewcomerTimelineComponent implements OnInit, OnDestroy {
  destroyRef = inject(DestroyRef);

  timelineItems: NewcomerTimelineComponent_TimelineFragmentFragment[] = [];
  subscriptions: Subscription[] = [];
  save$ = new Subject<typeof this.form.value>();
  placementOptions = PLACEMENT_STATUS_OPTIONS;
  userHasRightsToSensitiveData = false;

  public formContainer = this.formHelperService.getFormContainer(models.Note, (fb) => ({
    id: fb.modelField('id'),
    text: fb.modelField('text'),
    newcomer: fb.modelField('newcomer', {
      fields: fb.fieldSelection('NewComer', {
        id: true
      })
    })
  }));
  public form = this.formContainer.form;
  public saveHelper = new FormSaveHelper(this.formContainer, this.messageService); 

  constructor(private apollo: Apollo,
              private route: ActivatedRoute,
              private errorService: ErrorService,
              private messageService: MessageService,
              private formHelperService: FormHelperService) { }

  ngOnInit(): void {
    const timelineQuerySubscription = this.route.params.pipe(
      filter(params => params.hasOwnProperty('newcomerId')),
      switchMap((params) => {
        const newcomerId = params['newcomerId'] as string;
        return this.apollo.watchQuery({
          query: TIMELINE_QUERY,
          variables: {id: newcomerId},
          fetchPolicy: 'network-only'
        }).valueChanges.pipe(
          catchError(() => this.errorService.HandleGraphQLError('Ophalen van tijdlijn'))
        );
      })
    ).subscribe(output => {
      if (output.data.newcomer) {
        this.timelineItems = [];
        this.timelineItems.push(...output.data.newcomer.timeline);
      }
    });

    this.form.controls.newcomer.setValue({
      id: this.route.snapshot.params['newcomerId']
    });

    this.saveHelper.onSave$.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(result => {
      if(result.errors.length != 0) {
        return;
      }

      const cache = this.apollo.client.cache;
      cache.evict({
        id: `NewComer:${this.route.snapshot.params['newcomerId']}`,
        fieldName: 'timeline'
      });
      this.form.controls.text.setValue('');
    });

    const rightsQuerySubscription = this.apollo.query({
      query: RIGHTS_QUERY
    }).pipe(
      catchError(() => this.errorService.HandleGraphQLError('Ophalen van rechten'))
    ).subscribe(output => {
      this.userHasRightsToSensitiveData = output.data.me.managerHasAccessToSensitiveMessages;
    });

    this.subscriptions.push(rightsQuerySubscription);
    this.subscriptions.push(timelineQuerySubscription);
  }

  assertTimelineType(timelineItem: NewcomerTimelineComponent_TimelineFragmentFragment): NewcomerTimelineComponent_TimelineFragmentFragment {
    return timelineItem;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
