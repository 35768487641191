import { Component, input } from '@angular/core';
import { FieldInputType } from '@vasio-nl/valow';
import { PlacementOfferResolution } from 'src/gql/graphql';

@Component({
  selector: 'app-placement-offer-resolution-column',
  templateUrl: './placement-offer-resolution-column.component.html',
  styleUrls: ['./placement-offer-resolution-column.component.scss']
})
export class PlacementOfferResolutionColumnComponent {

  value = input<string>();
  field = input.required<FieldInputType>();
  rowData = input<any>();

  placementOfferOptions: { [key in keyof typeof PlacementOfferResolution]: 'success' | 'secondary' | 'info' | 'warning' | 'danger' | 'contrast' } = {
    ACCEPTED: 'success',
    REJECTED: 'danger',
    AWAITING_RESOLUTION: 'info',
    CANCELLED: 'danger',
  };

  constructor() { }

  getColor() {
    const typedValue = this.value() as PlacementOfferResolution;
    return this.placementOfferOptions[typedValue];
  }
}
