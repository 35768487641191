import {Gender, Language, PlacementOfferResolution, PlacementStatus, PoVo} from "../../gql/graphql";
import {AvailableModels, FieldType} from "@vasio-nl/valow";

export const models = {
  Location: {
    translations: {
      en: {
        displayName: "Location"
      },
      nl: {
        displayName: "Locatie"
      }
    },
    schemaInfo: {
      querySingle: "location" as const,
      queryMultiple: "locations" as const,
      queryMultiplePaginationStrategy: 'relay' as const,
      mutation: null,
      mutationObjectResponseName: "location" as const
    },
    displayField: 'name',
    fields: {
      capacityDisplay: {
        type: FieldType.STRING as const,
        translations: {
          en: {
            displayName: "Capacity"
          },
          nl: {
            displayName: "Capaciteit"
          }
        }
      },
      cityAreaName: {
        type: FieldType.STRING as const,
        translations: {
          en: {
            displayName: "City area"
          },
          nl: {
            displayName: "Stadsdeel"
          }
        }
      },
      capacityUpdatedAt: {
        type: FieldType.DATE as const,
        translations: {
          en: {
            displayName: "Capacity updated"
          },
          nl: {
            displayName: "Capaciteit bijgewerkt"
          }
        }
      },
      oldestPlacementOffer: {
        type: FieldType.DATE as const,
        translations: {
          en: {
            displayName: "Oldest placement offer"
          },
          nl: {
            displayName: "Oudste openstaande plaatsingsvoorstel"
          }
        }
      },
      lastReactionOnPlacementOffer: {
        type: FieldType.DATE as const,
        translations: {
          en: {
            displayName: "Last reaction"
          },
          nl: {
            displayName: "Laatste reactie"
          }
        }
      },
      name: {
        type: FieldType.STRING as const,
        translations: {
          en: {
            displayName: "Name"
          },
          nl: {
            displayName: "Naam"
          }
        },
        defaultValue: ''
      },
      id: {
        type: FieldType.STRING as const,
        translations: {
          en: {
            displayName: "ID"
          },
          nl: {
            displayName: "ID"
          }
        }
      },
      capacity: {
        type: FieldType.RELATION_MANY as const,
        translations: {
          en: {
            displayName: "Capacity"
          },
          nl: {
            displayName: "Capaciteit"
          }
        },
        fields: {
          id: true
        } as const,
        model: "Capacity" as const
      }
    }
  },
  Capacity: {
    translations: {
      en: {
        displayName: "Capacity"
      },
      nl: {
        displayName: "Capaciteit"
      }
    },
    schemaInfo: {
      querySingle: null,
      queryMultiple: null,
      queryMultiplePaginationStrategy: null,
      mutation: null,
      mutationObjectResponseName: null
    },
    displayField: 'id',
    fields: {
      id: {
        type: FieldType.STRING as const,
        translations: {
          en: {
            displayName: "ID"
          },
          nl: {
            displayName: "ID"
          }
        }
      },
      age: {
        type: FieldType.NUMBER as const,
        translations: {
          en: {
            displayName: "Age"
          },
          nl: {
            displayName: "Leeftijd"
          }
        },
        defaultValue: null
      },
      capacity: {
        type: FieldType.NUMBER as const,
        translations: {
          en: {
            displayName: "Capacity"
          },
          nl: {
            displayName: "Capaciteit"
          }
        },
        defaultValue: null
      },
      description: {
        type: FieldType.STRING as const,
        translations: {
          en: {
            displayName: "Description"
          },
          nl: {
            displayName: "Omschrijving"
          }
        },
        defaultValue: ''
      },
      remainingCapacity: {
        type: FieldType.NUMBER as const,
        translations: {
          en: {
            displayName: "Age"
          },
          nl: {
            displayName: "Leeftijd"
          }
        },
        defaultValue: null
      }
    }
  },
  PlacementOffer: {
    translations: {
      en: {
        displayName: "Placement offer"
      },
      nl: {
        displayName: "Plaatsingsvoorstel"
      }
    },
    schemaInfo: {
      querySingle: "placementOffer" as const,
      queryMultiple: "placementOffers" as const,
      queryMultiplePaginationStrategy: 'relay' as const,
      mutation: "createOrUpdatePlacementOffer" as const,
      mutationObjectResponseName: "placementOffer" as const
    },
    displayField: 'id',
    fields: {
      id: {
        type: FieldType.STRING as const,
        translations: {
          en: {
            displayName: "ID"
          },
          nl: {
            displayName: "ID"
          }
        }
      },
      date: {
        type: FieldType.DATE as const,
        translations: {
          en: {
            displayName: "Date"
          },
          nl: {
            displayName: "Datum"
          }
        },
        defaultValue: '',
        placeholder: 'dd-mm-YYYY'
      },
      newcomer: {
        type: FieldType.RELATION as const,
        translations: {
          en: {
            displayName: "Newcomer"
          },
          nl: {
            displayName: "Nieuwkomer"
          }
        },
        fields: {
          id: true,
          fullName: true
        } as const,
        model: "NewComer" as const
      },
      location: {
        type: FieldType.RELATION as const,
        translations: {
          en: {
            displayName: "Location"
          },
          nl: {
            displayName: "Locatie"
          }
        },
        fields: {
          id: true,
          name: true
        } as const,
        model: "Location" as const
      },
      capacity: {
        type: FieldType.RELATION as const,
        translations: {
          en: {
            displayName: "Capacity"
          },
          nl: {
            displayName: "Capaciteit"
          }
        },
        fields: {
          id: true,
          name: true
        } as const,
        model: "Capacity" as const
      },
      resolution: {
        type: FieldType.ENUM_SELECT as const,
        translations: {
          en: {
            displayName: "Resolution",
            options: {
              [PlacementOfferResolution.ACCEPTED]: 'Accepted',
              [PlacementOfferResolution.REJECTED]: 'Rejected',
              [PlacementOfferResolution.CANCELLED]: 'Cancelled',
              [PlacementOfferResolution.AWAITING_RESOLUTION]: 'Awaiting resolution'
            }
          },
          nl: {
            displayName: "Status",
            options: {
              [PlacementOfferResolution.ACCEPTED]: 'Geaccepteerd',
              [PlacementOfferResolution.REJECTED]: 'Geweigerd',
              [PlacementOfferResolution.CANCELLED]: 'Geannuleerd',
              [PlacementOfferResolution.AWAITING_RESOLUTION]: 'In afwachting'
            }
          }
        },
        defaultValue: 'WAITING'
      },
      resolutionAt: {
        type: FieldType.DATE as const,
        translations: {
          en: {
            displayName: "Resolution"
          },
          nl: {
            displayName: "Besluit"
          }
        },
        defaultValue: '',
        placeholder: 'dd-mm-YYYY'
      },
      needsFinalized: {
        type: FieldType.BOOLEAN as const,
        translations: {
          en: {
            displayName: "Needs finalized"
          },
          nl: {
            displayName: "Actie nodig"
          }
        },
        defaultValue: false,
        nullable: false
      },
      finalized: {
        type: FieldType.BOOLEAN as const,
        translations: {
          en: {
            displayName: "Finalized"
          },
          nl: {
            displayName: "Afgerond"
          }
        },
        defaultValue: false,
        nullable: false
      },
      locationName: {
        type: FieldType.STRING as const,
        translations: {
          en: {
            displayName: "Location"
          },
          nl: {
            displayName: "Locatie"
          }
        },
        defaultValue: ''
      },
      newcomerName: {
        type: FieldType.STRING as const,
        translations: {
          en: {
            displayName: "Newcomer"
          },
          nl: {
            displayName: "Nieuwkomer"
          }
        },
        defaultValue: ''
      }
    }
  },
  Note: {
    translations: {
      en: {
        displayName: "Note"
      },
      nl: {
        displayName: "Notitie"
      }
    },
    schemaInfo: {
      querySingle: "note" as const,
      queryMultiple: "notes",
      queryMultiplePaginationStrategy: 'relay' as const,
      mutation: "createNote" as const,
      mutationObjectResponseName: "note" as const
    },
    displayField: 'id',
    fields: {
      newcomer: {
        type: FieldType.RELATION as const,
        translations: {
          en: {
            displayName: "Newcomer"
          },
          nl: {
            displayName: "Nieuwkomer"
          }
        },
        fields: {
          id: true,
          firstName: true
        } as const,
        model: "newComer" as const
      },
      text: {
        type: FieldType.TEXTAREA as const,
        translations: {
          en: {
            displayName: "Text"
          },
          nl: {
            displayName: "Tekst"
          }
        },
        defaultValue: ''
      },
      id: {
        type: FieldType.STRING as const,
        translations: {
          en: {
            displayName: "ID"
          },
          nl: {
            displayName: "ID"
          }
        }
      },
    }
  },
  NewComer: {
    translations: {
      en: {
        displayName: "Newcomer"
      },
      nl: {
        displayName: "Nieuwkomer"
      }
    },
    schemaInfo: {
      querySingle: "newcomer" as const,
      queryMultiple: "newcomers" as const,
      queryMultiplePaginationStrategy: 'relay' as const,
      mutation: "updateNewcomer" as const,
      mutationObjectResponseName: "newcomer" as const
    },
    displayField: 'fullName',
    fields: {
      firstName: {
        type: FieldType.STRING as const,
        translations: {
          en: {
            displayName: "Firstname"
          },
          nl: {
            displayName: "Voornaam"
          }
        },
        defaultValue: 'test'
      },
      lastName: {
        type: FieldType.STRING as const,
        translations: {
          en: {
            displayName: "Lastname"
          },
          nl: {
            displayName: "Achternaam"
          }
        },
        defaultValue: ''
      },
      dateOfBirth: {
        type: FieldType.DATE as const,
        translations: {
          en: {
            displayName: "Date of birth"
          },
          nl: {
            displayName: "Geboortedatum"
          }
        },
        defaultValue: '',
        placeholder: 'dd-mm-YYYY'
      },
      age: {
        type: FieldType.NUMBER as const,
        translations: {
          en: {
            displayName: "Age"
          },
          nl: {
            displayName: "Leeftijd"
          }
        },
        defaultValue: null
      },
      gender: {
        type: FieldType.ENUM_SELECT as const,
        translations: {
          en: {
            displayName: 'Gender',
            options: {
              [Gender.MALE]: 'Boy',
              [Gender.FEMALE]: 'Girl',
              [Gender.OTHER]: 'Other'
            }
          },
          nl: {
            displayName: 'Geslacht',
            options: {
              [Gender.MALE]: 'Jongen',
              [Gender.FEMALE]: 'Meisje',
              [Gender.OTHER]: 'Anders'
            }
          }
        }
      },
      poVo: {
        type: FieldType.ENUM_SELECT as const,
        translations: {
          en: {
            displayName: 'Education type',
            options: {
              [PoVo.PO]: 'Primary education',
              [PoVo.VO]: 'Secondary education'
            }
          },
          nl: {
            displayName: 'Onderwijstype',
            options: {
              [PoVo.PO]: 'Primair onderwijs',
              [PoVo.VO]: 'Voortgezet onderwijs'
            }
          }
        }
      },
      poGroup: {
        type: FieldType.NUMBER as const,
        translations: {
          en: {
            displayName: "PO group"
          },
          nl: {
            displayName: "PO groep"
          }
        }
      },
      alreadyInNetherlands: {
        type: FieldType.BOOLEAN as const,
        translations: {
          en: {
            displayName: "Already in Netherlands"
          },
          nl: {
            displayName: "Reeds in Nederland"
          }
        },
        defaultValue: null,
        nullable: true
      },
      arrivalDate: {
        type: FieldType.DATE as const,
        translations: {
          en: {
            displayName: "Arrival date"
          },
          nl: {
            displayName: "Aankomstdatum"
          }
        }
      },
      registrationDate: {
        type: FieldType.DATE as const,
        translations: {
          en: {
            displayName: "Registration date"
          },
          nl: {
            displayName: "Aanmelddatum"
          }
        }
      },
      disabilitySupport: {
        type: FieldType.ENUM_MULTI_SELECT as const,
        translations: {
          en: {
            displayName: "Disability support",
            options: {
              'PHYSICAL_LIMITATION': 'Physical limitation',
              'DEVELOPMENTAL_DELAY': 'Developmental delay',
              'BEHAVIORAL_PROBLEMS': 'Behavioral problems',
              'CHRONIC_DISEASE': 'Chronic disease',
              'OTHER': 'Other'
            }
          },
          nl: {
            displayName: "extra ondersteuning",
            options: {
              'PHYSICAL_LIMITATION': 'Fysieke beperking',
              'DEVELOPMENTAL_DELAY': 'Ontwikkelingsachterstand',
              'BEHAVIORAL_PROBLEMS': 'Gedragsproblemen',
              'CHRONIC_DISEASE': 'Chronische ziekte',
              'OTHER': 'Anders'
            }
          }
        }
      },
      disabilitySupportExplanationNote: {
        type: FieldType.STRING as const,
        translations: {
          en: {
            displayName: "Disability support explanation note"
          },
          nl: {
            displayName: "Toelichting ondersteuning"
          }
        },
        defaultValue: ''
      },
      parentFirstName: {
        type: FieldType.STRING as const,
        translations: {
          en: {
            displayName: 'Firstname'
          },
          nl: {
            displayName: 'Voornaam'
          }
        },
        defaultValue: ''
      },
      parentLastName: {
        type: FieldType.STRING as const,
        translations: {
          en: {
            displayName: 'Lastname'
          },
          nl: {
            displayName: 'Achternaam'
          }
        },
        defaultValue: ''
      },
      parentPhone: {
        type: FieldType.STRING as const,
        translations: {
          en: {
            displayName: 'Phone'
          },
          nl: {
            displayName: 'Telefoon'
          }
        },
        defaultValue: ''
      },
      parentEmail: {
        type: FieldType.STRING as const,
        translations: {
          en: {
            displayName: 'Email'
          },
          nl: {
            displayName: 'Email'
          }
        },
        defaultValue: ''
      },
      contactFirstName: {
        type: FieldType.STRING as const,
        translations: {
          en: {
            displayName: 'Firstname'
          },
          nl: {
            displayName: 'Voornaam'
          }
        },
        defaultValue: ''
      },
      contactLastName: {
        type: FieldType.STRING as const,
        translations: {
          en: {
            displayName: 'Lastname'
          },
          nl: {
            displayName: 'Achternaam'
          }
        },
        defaultValue: ''
      },
      contactPhone: {
        type: FieldType.STRING as const,
        translations: {
          en: {
            displayName: 'Phone'
          },
          nl: {
            displayName: 'Telefoon'
          }
        },
        defaultValue: ''
      },
      contactEmail: {
        type: FieldType.STRING as const,
        translations: {
          en: {
            displayName: 'Email'
          },
          nl: {
            displayName: 'Email'
          }
        },
        defaultValue: ''
      },
      street: {
        type: FieldType.STRING as const,
        translations: {
          en: {
            displayName: "Street"
          },
          nl: {
            displayName: "Straat"
          }
        },
        defaultValue: ''
      },
      houseNumber: {
        type: FieldType.STRING as const,
        translations: {
          en: {
            displayName: "House number"
          },
          nl: {
            displayName: "Huisnummer"
          }
        }
      },
      zip: {
        type: FieldType.STRING as const,
        translations: {
          en: {
            displayName: "Zip"
          },
          nl: {
            displayName: "Postcode"
          }
        },
        defaultValue: ''
      },
      city: {
        type: FieldType.STRING as const,
        translations: {
          en: {
            displayName: "City"
          },
          nl: {
            displayName: "Stad"
          }
        },
        defaultValue: ''
      },
      willMoveSoon: {
        type: FieldType.BOOLEAN as const,
        translations: {
          en: {
            displayName: "Will move soon"
          },
          nl: {
            displayName: "Gaat binnenkort verhuizen"
          }
        },
        defaultValue: false,
        nullable: false
      },
      newStreet: {
        type: FieldType.STRING as const,
        translations: {
          en: {
            displayName: "Street"
          },
          nl: {
            displayName: "Straat"
          }
        },
        defaultValue: ''
      },
      newHouseNumber: {
        type: FieldType.STRING as const,
        translations: {
          en: {
            displayName: "House number"
          },
          nl: {
            displayName: "Huisnummer"
          }
        }
      },
      newZip: {
        type: FieldType.STRING as const,
        translations: {
          en: {
            displayName: "Zip"
          },
          nl: {
            displayName: "Postcode"
          }
        },
        defaultValue: ''
      },
      newCity: {
        type: FieldType.STRING as const,
        translations: {
          en: {
            displayName: "City"
          },
          nl: {
            displayName: "Stad"
          }
        },
        defaultValue: ''
      },
      languages: {
        type: FieldType.ENUM_MULTI_SELECT as const,
        translations: {
          en: {
            displayName: "Languages",
            options: {
              [Language.GB]: "English",
              [Language.NL]: "Dutch",
              [Language.RU]: "Russian",
              [Language.UA]: "Ukrainian",
              [Language.OTHER]: "Other"
            }
          },
          nl: {
            displayName: "Talen",
            options: {
              [Language.GB]: "Engels",
              [Language.NL]: "Nederlands",
              [Language.RU]: "Russisch",
              [Language.UA]: "Oekraïens",
              [Language.OTHER]: "Anders"
            }
          }
        }
      },
      otherLanguages: {
        type: FieldType.STRING as const,
        translations: {
          en: {
            displayName: "Other languages"
          },
          nl: {
            displayName: "Andere talen"
          }
        },
        defaultValue: ''
      },
      hasInterpreter: {
        type: FieldType.BOOLEAN as const,
        translations: {
          en: {
            displayName: "Has interpreter"
          },
          nl: {
            displayName: "Kent iemand die kan vertalen"
          }
        },
        defaultValue: false,
        nullable: false
      },
      isUkrainian: {
        type: FieldType.BOOLEAN as const,
        translations: {
          en: {
            displayName: "Is from Ukraine"
          },
          nl: {
            displayName: "Komt uit Oekraïne"
          }
        },
        defaultValue: null,
        nullable: true
      },
      remarks: {
        type: FieldType.TEXTAREA as const,
        translations: {
          en: {
            displayName: "Remarks"
          },
          nl: {
            displayName: "Opmerkingen van aanmelder"
          }
        },
        defaultValue: ''
      },
      CDW: {
        type: FieldType.STRING as const,
        translations: {
          en: {
            displayName: "CDW number"
          },
          nl: {
            displayName: "CDW nummer"
          }
        },
        defaultValue: '',
        placeholder: 'LBA_LPA_xxxxxxxxxxx'
      },
      LBAArrivalDate: {
        type: FieldType.DATE as const,
        translations: {
          en: {
            displayName: "In The Hague since"
          },
          nl: {
            displayName: "In Den Haag sinds datum"
          }
        },
        defaultValue: '',
        placeholder: 'dd-mm-YYYY'
      },
      LBACurrentResidence: {
        type: FieldType.STRING as const,
        translations: {
          en: {
            displayName: "Current residence"
          },
          nl: {
            displayName: "Huidige woonplaats"
          }
        },
        defaultValue: '',
        placeholder: 'Den haag'
      },
      LBASchool: {
        type: FieldType.STRING as const,
        translations: {
          en: {
            displayName: "School"
          },
          nl: {
            displayName: "School"
          }
        },
        defaultValue: '',
        placeholder: 'School naam'
      },
      LBASchoolPhone: {
        type: FieldType.STRING as const,
        translations: {
          en: {
            displayName: "School phone"
          },
          nl: {
            displayName: "School telefoon"
          }
        },
        defaultValue: '',
        placeholder: '+31 6 12345678'
      },
      LBASchoolTownship: {
        type: FieldType.STRING as const,
        translations: {
          en: {
            displayName: "School township"
          },
          nl: {
            displayName: "School gemeente"
          }
        },
        defaultValue: '',
        placeholder: 'Den haag'
      },
      id: {
        type: FieldType.STRING as const,
        translations: {
          en: {
            displayName: "#"
          },
          nl: {
            displayName: "#"
          }
        }
      },
      fullName: {
        type: FieldType.STRING as const,
        translations: {
          en: {
            displayName: "Name"
          },
          nl: {
            displayName: "Naam"
          }
        },
        defaultValue: ''
      },
      placementStatus: {
        type: FieldType.ENUM_SELECT as const,
        translations: {
          en: {
            displayName: "Status",
            options: {
              [PlacementStatus.NEW]: "New",
              [PlacementStatus.SIGNUP_PO]: "Signup PO",
              [PlacementStatus.DOUBLE_REGISTRATION]: "Double registration",
              [PlacementStatus.PLACED]: "Placed newcomers group (regular)",
              [PlacementStatus.PLACED_ARRIVAL_LOCATION]: "Placed newcomers group (emergency plan)",
              [PlacementStatus.PLACED_TEMPORARY_LOCATION]: "Placed newcomers group (TOV)",
              [PlacementStatus.COMPULSORY_EDUCATION]: "Compulsory education",
              [PlacementStatus.YET_TO_ARRIVE]: "Yet to arrive in The Hague",
              [PlacementStatus.RESERVED]: "Reserved newcomers group (regular)",
              [PlacementStatus.LEFT]: "Left municipality",
              [PlacementStatus.WAITING_LIST]: "Waiting list",
              [PlacementStatus.WAITING_LIST_ARRIVAL_LOCATION]: "Reserved newcomers group (emergency plan)",
              [PlacementStatus.WAITING_LIST_TEMPORARY_LOCATION]: "Reserved newcomers group (TOV)",
              [PlacementStatus.WAITING_LIST_CARE]: "Waiting list (care)",
              [PlacementStatus.IMPROVEMENT_NEEDED]: "Placement improvement",
              [PlacementStatus.OUTSIDE_THE_HAGUE]: "Outside The Hague",
              [PlacementStatus.TODDLER]: "Toddler",
              [PlacementStatus.TODDLER_WAITING_LIST]: "Toddler waiting list",
              [PlacementStatus.REFUSSAL]: "Refussal",
              [PlacementStatus.SIGNUP_VO]: "Signup VO",
              [PlacementStatus.NO_CONTACT]: "No contact",
              [PlacementStatus.ALREADY_FOUND_A_SCHOOL]: "Already found a school",
              [PlacementStatus.EXEMPT_FROM_EDUCATION]: "Exempt from education"
            }
          },
          nl: {
            displayName: "Status",
            options: {
              [PlacementStatus.NEW]: "Nieuw",
              [PlacementStatus.SIGNUP_PO]: "Aanmelden PO",
              [PlacementStatus.DOUBLE_REGISTRATION]: "Dubbel",
              [PlacementStatus.PLACED]: "Geplaatst Nieuwkomersgroep (regulier)",
              [PlacementStatus.PLACED_ARRIVAL_LOCATION]: "Geplaatst Nieuwkomersgroep (noodplan)",
              [PlacementStatus.PLACED_TEMPORARY_LOCATION]: "Geplaatst Nieuwkomersgroep (TOV)",
              [PlacementStatus.COMPULSORY_EDUCATION]: "Leerplicht",
              [PlacementStatus.YET_TO_ARRIVE]: "Nog niet in Den haag",
              [PlacementStatus.RESERVED]: "Reservering Nieuwkomersgroep (regulier)",
              [PlacementStatus.LEFT]: "Vertrokken uit gemeente",
              [PlacementStatus.WAITING_LIST]: "Op wachtlijst",
              [PlacementStatus.WAITING_LIST_ARRIVAL_LOCATION]: "Reservering Nieuwkomersgroep (noodplan)",
              [PlacementStatus.WAITING_LIST_TEMPORARY_LOCATION]: "Reservering Nieuwkomersgroep (TOV)",
              [PlacementStatus.WAITING_LIST_CARE]: "Op wachtlijst (zorg)",
              [PlacementStatus.IMPROVEMENT_NEEDED]: "Plaatsverbetering",
              [PlacementStatus.OUTSIDE_THE_HAGUE]: "Buiten Den Haag",
              [PlacementStatus.TODDLER]: "Kleuter",
              [PlacementStatus.TODDLER_WAITING_LIST]: "Wachtlijst Kleuter",
              [PlacementStatus.REFUSSAL]: "Plek geweigerd",
              [PlacementStatus.SIGNUP_VO]: "Aanmelden VO",
              [PlacementStatus.NO_CONTACT]: "Geen contact",
              [PlacementStatus.ALREADY_FOUND_A_SCHOOL]: "Reeds een school gevonden",
              [PlacementStatus.EXEMPT_FROM_EDUCATION]: "Vrijgesteld van onderwijs"
            }
          }
        }
      },
      centralSignupRemarks: {
        type: FieldType.TEXTAREA as const,
        translations: {
          en: {
            displayName: "Central signup remarks"
          },
          nl: {
            displayName: "Centrale aanmelding opmerkingen"
          }
        },
        defaultValue: ''
      },
      schoolPlacement: {
        type: FieldType.RELATION as const,
        translations: {
          en: {
            displayName: "School placement"
          },
          nl: {
            displayName: "School plaatsing"
          }
        },
        fields: {
          id: true,
          name: true
        } as const,
        model: "Location" as const
      },
      placementOffers: {
        type: FieldType.RELATION_MANY as const,
        translations: {
          en: {
            displayName: "Placement offers"
          },
          nl: {
            displayName: "Plaatsingsvoorstellen"
          }
        },
        fields: {
          id: true
        } as const,
        model: "PlacementOffer" as const
      },
      latitude: {
        type: FieldType.NUMBER as const,
        translations: {
          en: {
            displayName: "Latitude"
          },
          nl: {
            displayName: "Breedtegraad"
          }
        }
      },
      longitude: {
        type: FieldType.NUMBER as const,
        translations: {
          en: {
            displayName: "Longitude"
          },
          nl: {
            displayName: "Lengtegraad"
          }
        }
      }
    }
  },
  User: {
    translations: {
      en: {
        displayName: "User"
      },
      nl: {
        displayName: "Gebruiker"
      }
    },
    schemaInfo: {
      querySingle: "user" as const,
      queryMultiple: "users",
      queryMultiplePaginationStrategy: 'offset' as const,
      mutation: "createOrUpdateUser" as const,
      mutationObjectResponseName: "user" as const
    },
    displayField: 'email',
    fields: {
      id: {
        type: FieldType.STRING as const,
        translations: {
          en: {
            displayName: "ID"
          },
          nl: {
            displayName: "ID"
          }
        }
      },
      firstName: {
        type: FieldType.STRING as const,
        translations: {
          en: {
            displayName: "Firstname"
          },
          nl: {
            displayName: "Voornaam"
          }
        },
        defaultValue: '',
        placeholder: 'Voornaam'
      } as const,
      lastName: {
        type: FieldType.STRING as const,
        translations: {
          en: {
            displayName: "Lastname"
          },
          nl: {
            displayName: "Achternaam"
          }
        },
        defaultValue: '',
        placeholder: 'Achternaam'
      },
      email: {
        type: FieldType.STRING as const,
        translations: {
          en: {
            displayName: "Email"
          },
          nl: {
            displayName: "Email"
          }
        },
        defaultValue: '',
        placeholder: 'persoon@school.com'
      },
      newcomerLocations: {
        type: FieldType.RELATION_MANY as const,
        translations: {
          en: {
            displayName: "Newcomer Locations"
          },
          nl: {
            displayName: "Nieuwkomer Locaties"
          }
        },
        fields: {
          id: true,
          name: true
        } as const,
        model: "Location" as const
      },
      otpActive: {
        type: FieldType.BOOLEAN as const,
        translations: {
          en: {
            displayName: "OTP Active"
          },
          nl: {
            displayName: "OTP Actief"
          }
        },
        defaultValue: false,
        nullable: false
      }
    }
  }
} satisfies AvailableModels;

